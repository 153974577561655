
    .isShowActive{
        background: #FFECC8!important;
        border-color: #ffffff!important;
    }
    /deep/.is-checked .el-radio__inner{
        border-color: #FD4446!important;
        background: #FD4446!important;
    }
    /deep/.el-radio__inner{
        width: 20px;
        height: 20px;
    }
    /deep/.is-checked .el-radio__label{
        color: #333333!important;
        font-size: 18px;
        font-weight: 500;
    }
    /deep/.el-radio .el-radio__label{
        color: #333333;
        font-size: 18px;
        font-weight: 500;
    }
    .budget-item {
        /deep/ .budge-radio {
            .el-radio__label{
                color: #1E63F1;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
    /deep/.el-form-item__label{
        color: #333333;
        font-size: 18px;
        font-weight: 500;
    }
    .PlanStepTwo{
        height: calc(100vh - 200px);
        padding: 20px 30px;
        .PlanStepTwoHeader{
            color: #333333;
            font-size: 28px;
        }
        .PlanStepTwoForm{
            margin-top: 70px;
            padding-left: 40px;
        }
        .nextBackBtn{
            padding-left: 136px;
            padding-top: 20px;
        }
    }
    .CustomBudgetBox{
        display: flex;
        flex-direction: column;
        padding: 40px 60px;
        box-sizing: border-box;
        height: 100vh;
        span{
            color: #989898;
            font-size: 18px;
            line-height: 1;
            margin-top: 20px;
        }
        .title{
            color: #333333;
            font-size: 24px;
            padding-bottom: 20px;
        }
        span:nth-child(4){
            line-height: 27px;
        }
        .box-bottom{
            margin-top: 30px;
            font-size: 18px;
            color: #333333;
            /deep/ .el-input-number {
                .el-input__inner {
                    text-align: left;
                }
            }
        }
    }
    .CustomBudget-radio{
        margin-top: 30px;
        font-size: 18px;
        color: #333333;
    }
    .hoverWrapper {
        padding: 10px 10px 10px 20px;
        z-index: 9999;
        position: fixed;
        top: 10px;
        left: 20px;
        width: 210px;
        /*height: 200px;*/
        background-color: #FFF;
        border: 1px solid #EEE;
        font-size: 18px;
        p{
            line-height: 27px;
        }
    }
    .clickWrapper{
        z-index: 9999;
        position: fixed;
        top: 10px;
        left: 20px;
        width: 246px;
        padding-top: 20px;
        background-color: #FFF;
        border: 1px solid #EEE;
        padding-left: 20px;
        font-size: 18px;
        padding-bottom: 30px;
        /deep/.el-radio{
            margin-top: 20px;
        }
        /deep/.el-radio-group{
            display: flex;
            flex-direction: column;
        }
        /deep/.el-radio__label{
            color: #333333!important;
            font-size: 18px;
        }
        .customInput{
            margin-top: 10px;
            display: flex;
            margin-left: 30px;
            padding-right: 34px;
            align-items: center;
            span{
                font-size: 18px;
            }
        }
        .clickWrapperBtn{
            margin-top: 20px;
            padding-left: 44px;
        }
    }
/* 去掉 el-select 输入框的边框 */
// .el-input {
//   border: none;
// }

/* 隐藏 el-select 输入框的内容 */

.selectPlan{
    width: 180px;
    height: 40px;
    display: block;
    background-color: rgb(255,255,255);
    position: absolute;
    top: 0;
    z-index: 80;
    color: #409EFF;
    border: none;
    padding-right: 60px;
    font-size: 16px;
    cursor: pointer;
}
.noData{
    color: #66b1ff !important;

}
.name{
    display: flex;
}
.selected{
    margin-left: 20px;
    position: relative;


}

